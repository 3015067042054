import { CameraOpener, SDKEnvironmentTypes, SelfieCameraTypes, UnicoCheckBuilder, UnicoConfig, UnicoThemeBuilder } from 'unico-webframe'
import { getConfig } from '../../config/config'
import {PublicConfigurations} from '../../redux/reducers/public-configurations/public-configurations.reducer'
import LocalStorageWrapper, {LocalStorageItem} from '../../common/assets/utils/LocalStorageWrapper'

class UnicoSDKService {
  camera: Promise<CameraOpener> | undefined
  constructor() {
    // const config = getConfig().biometryConfig.unicoLivenessConfig
    const config = this.getBiometryConfig()

    const unicoConfig = new UnicoConfig()
      .setHostname("https://dev-vendas.odrescred.com.br") 
      .setHostKey("r001-2dcd7dda-fe39-4c40-9255-0c24e5d6b405")

    // const unicoConfig = new UnicoConfig()
    //   .setProjectNumber(config.projectNumber)
    //   .setProjectId(config.projectId)
    //   .setMobileSdkAppId(config.mobileSDKAppId)
    //   .setHostname(config.hostname)
    //   .setHostInfo(config.hostInfo)
    //   .setHostKey(config.hostKey)

    console.log("unicoConfig", unicoConfig)

    const unicoCameraBuilder = new UnicoCheckBuilder()

    console.log("unicoCameraBuilder", unicoCameraBuilder)

    const unicoTheme = new UnicoThemeBuilder()
      .setColorSilhouetteSuccess('#11b048')
      .setColorSilhouetteError('#D50000')
      .setColorSilhouetteNeutral('#fcfcfc')
      .setBackgroundColor('#dff1f5')
      .setColorText('#6204ff')
      .setBackgroundColorComponents('#6204ff')
      .setColorTextComponents('#dff1f5')
      .setBackgroundColorButtons('#6204ff')
      .setColorTextButtons('#dff1f5')
      .setBackgroundColorBoxMessage('#fff')
      .setColorTextBoxMessage('#000')
      .setHtmlPopupLoading(
        `<div style="position: absolute; top: 45%; right: 50%; transform:
  translate(50%, -50%); z-index: 10; text-align: center; height: 100%;">Carregando...</div>`
      )
      .build()

    unicoCameraBuilder.setEnvironment(SDKEnvironmentTypes.UAT)
    // unicoCameraBuilder.setModelsPath("../../../public/models").setResourceDirectory("../../../public/resources")
    unicoCameraBuilder.setModelsPath("/models").setResourceDirectory("/resources")
    unicoCameraBuilder.setTheme(unicoTheme)

    console.log("unicoCameraBuilder", unicoCameraBuilder)

    const unicoCamera = unicoCameraBuilder.build()

    console.log("unicoCamera", unicoCamera)
    this.camera = unicoCamera.prepareSelfieCamera(unicoConfig, SelfieCameraTypes.SMART)
  }

  private getBiometryConfig() {
    const publicConfigurations: PublicConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.PUBLIC_CONFIGURATIONS)
    return publicConfigurations?.biometry?.unicoLivenessConfig
  }
}

export default UnicoSDKService
