import { Button, Card, Elevation } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import LocalStorageWrapper, { LocalStorageItem } from '../../common/assets/utils/LocalStorageWrapper'
import { storeIsNotSetUp } from '../../common/ErrorStrings'
import { StoreSelection } from '../../molecules/StoreSelection/StoreSelection'
import { OperatorCenterHelper } from '../../pages/Operators/OperatorCenterHelper'
import ConfigurationsSliceReducer from '../../redux/reducers/configurations/configurations.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { StoreDto } from '../../services/bff/retailers/dto/Store.dto'
import { CaptureMethodConfig, CaptureMethodUIConfig } from '../../services/captureMethodService/CaptureMethodService'
import { formItemsContainerStyle } from './style'

export interface IStoreConfigurationState {
  stores: Map<string, StoreDto>
  selectedStore?: StoreDto

  selectedCaptureMethod?: CaptureMethodConfig
  allowedCaptureMethods?: CaptureMethodUIConfig[]
}

export interface StoreConfigurationProps {
  history?: any
  location?: any
}
const StoreConfiguration: React.FC<StoreConfigurationProps> = ({ history, location }) => {
  const { configurations } = useTypedSelector(state => ({
    configurations: state.configurations.configurations,
  }))

  const [selectedStore, setSelectedStore] = useState<StoreDto | undefined>(undefined)

  const dispatch = useDispatch()

  useEffect(() => {
    const localStorageStore = bffParceirosApiService.coordinator.getStore()
    if (!localStorageStore) toaster.showWarningToast(storeIsNotSetUp)
  }, [])

  useEffect(() => {
    if (selectedStore) {
      const localStorageOperator = LocalStorageWrapper.getItem(LocalStorageItem.OPERATOR)
      return OperatorCenterHelper.redirectToOperatorHome(configurations, localStorageOperator.role)
    }
  }, [configurations])

  const handleConfirmStore = async () => {
    if (selectedStore) {
      dispatch(ConfigurationsSliceReducer.actions.fetchConfigurations({}))

      toaster.showSuccessToast('Loja configurada com sucesso!')
    }
  }

  return (
    <div style={formContainerStyle}>
      <div>
        <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
          <CardTitle>Configuração de Loja</CardTitle>

          <StoreSelection handleSelectedStore={setSelectedStore} />

          <Button style={{ marginTop: '32px' }} onClick={handleConfirmStore} intent="primary">
            Confirmar Loja
          </Button>
        </Card>
      </div>
    </div>
  )
}

export default StoreConfiguration

const CardTitle = styled.h3`
  align-self: flex-start;
  margin-bottom: 1.5em;
`

const formContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100vh',
  marginTop: '5vh',
  // width: '100vw',
  flexDirection: 'column' as 'column',
}
