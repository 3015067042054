import React from 'react'
import { useDispatch } from 'react-redux'
import { isBudgetsButtonAllowed } from '../../common/AccessHelper'
import { ReactComponent as ApplicationIcon } from '../../common/assets/images/application-icon.svg'
import { ReactComponent as BarCodeIcon } from '../../common/assets/images/barcode-icon.svg'
import { ReactComponent as DollarSignIcon } from '../../common/assets/images/dollar-sign-icon.svg'
import { ReactComponent as TrendingUpIcon } from '../../common/assets/images/trending-up-icon.svg'
import { ReactComponent as UpdateBorrowerDataIcon } from '../../common/assets/images/update-data-icon.svg'
import { navigateToPath } from '../../common/Navigation'
import { getConfig } from '../../config/config'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import BudgetSliceReducer from '../../redux/reducers/budget/budget.reducer'
import ContractsSimulatorSliceReducer from '../../redux/reducers/contracts-simulator/contracts-simulator.reducer'
import InvoicesSliceReducer from '../../redux/reducers/invoice/invoices.reducer'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import analyticsService from '../../services/analytics/analytics.service'
import { bffParceirosApiService } from '../../services/bff'

interface BudgetProps {
  isAllowed: boolean
}

interface ContractSimulatorProps {
  isAllowed: boolean
  simulationMaxValue: number
  simulationFlowStartedFrom: PagesPathEnum
}

interface UpdateBorrowerDataProps {
  isAllowed: boolean
}

interface ApplicationProps {
  isAllowed: boolean
  analyticsEvent?: string
}

export interface UserAvailableProductsProps {
  borrowerId?: string
  document?: string
  options: {
    budget?: BudgetProps
    contractSimulation?: ContractSimulatorProps
    updateBorrowerData?: UpdateBorrowerDataProps
    application?: ApplicationProps
  }
}

export const UserAvailableProducts = ({ borrowerId, document, options }: UserAvailableProductsProps) => {
  const { budget, contractSimulation, updateBorrowerData, application } = options

  const { isCreatingBorrowerNextInvoice, configurations, publicConfigurations } = useTypedSelector(state => ({
    isCreatingBorrowerNextInvoice: state.invoices.isCreatingBorrowerNextInvoice,
    configurations: state.configurations.configurations,
    publicConfigurations: state.publicConfigurations.configurations,
  }))

  const dispatch = useDispatch()

  const handleBudgetButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    dispatch(BudgetSliceReducer.actions.resetRequest())
    dispatch(
      BudgetSliceReducer.actions.setInitialCpf({
        initialCpf: document!,
      })
    )

    navigateToPath(PagesPathEnum.BUDGET_PAGE)
  }

  const handleGenerateBorrowerNextInvoiceButton = (borrowerId: string) => {
    dispatch(InvoicesSliceReducer.actions.createBorrowerNextInvoice(borrowerId))
  }

  const handleUpdateBorrowerDataButton = () => {
    navigateToPath(PagesPathEnum.UPDATE_CUSTOMER_DATA)
  }

  const handleContractSimulatorButton = () => {
    const simulationMinValue = getConfig().contractSimulation.minimumSimulationValue

    dispatch(
      ContractsSimulatorSliceReducer.actions.setSimulationParams({
        borrowerId,
        simulationMinValue,
        simulationMaxValue: contractSimulation?.simulationMaxValue,
        simulationFlowStartedFrom: contractSimulation?.simulationFlowStartedFrom,
      })
    )

    navigateToPath(PagesPathEnum.CONTRACT_SIMULATOR)
  }

  const handleApplicationButton = () => {
    if (application?.analyticsEvent) analyticsService.sendEvent(application.analyticsEvent)
    navigateToPath(PagesPathEnum.APPLICATION.replace(':cpf', document!) as PagesPathEnum)
  }

  const localStorageStore = bffParceirosApiService.coordinator.getStore()

  const shouldRenderBudgetButton = !!budget?.isAllowed && !!document && isBudgetsButtonAllowed(publicConfigurations, configurations)
  const shouldRenderGenerateBorrowerNextInvoiceButton = !!localStorageStore?.settings?.enableToGenerateBorrowerInvoice && !!borrowerId
  const shouldRenderContractSimulatorButton = !!contractSimulation?.isAllowed && !!contractSimulation?.simulationMaxValue && !!borrowerId
  const shouldRenderApplicationButton = !!application?.isAllowed && !!document

  return [
    {
      shouldRender: shouldRenderBudgetButton,
      title: 'Solicitar aumento de limite',
      icon: <TrendingUpIcon />,
      onButtonPress: handleBudgetButton,
    },
    {
      shouldRender: shouldRenderContractSimulatorButton,
      title: 'Simular parcelamento',
      icon: <DollarSignIcon />,
      onButtonPress: handleContractSimulatorButton,
    },
    {
      shouldRender: shouldRenderGenerateBorrowerNextInvoiceButton,
      title: 'Imprimir próximo boleto',
      icon: <BarCodeIcon />,
      onButtonPress: () => handleGenerateBorrowerNextInvoiceButton(borrowerId!),
      isLoading: isCreatingBorrowerNextInvoice,
    },
    {
      shouldRender: !!updateBorrowerData?.isAllowed,
      title: 'Alterar dados cadastrais do cliente',
      icon: <UpdateBorrowerDataIcon />,
      onButtonPress: () => handleUpdateBorrowerDataButton(),
    },
    {
      shouldRender: shouldRenderApplicationButton,
      title: 'Cadastrar cliente',
      icon: <ApplicationIcon />,
      onButtonPress: handleApplicationButton,
    },
  ]
}
