import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { getSentryDSN } from './config/config'
import { ManifestSelector } from './config/manifest-selector'
import './index.css'
import analyticsService from './services/analytics/analytics.service'
import * as serviceWorker from './serviceWorker'

// manifest.json provides metadata used when your web app is installed on a
// user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/
const manifestPath = ManifestSelector.getPath()
const link = document.createElement('link')
link.rel = 'manifest'
link.href = manifestPath
document.head.appendChild(link)

if (getSentryDSN()) {
  Sentry.init({
    dsn: getSentryDSN(),
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

analyticsService.initalize()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
