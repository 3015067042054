import { getAppName } from '../common/AssetsHelper'
import { AppNameEnum } from '../services/bff/configurations/enums/public-configurations.enum'

export class ManifestSelector {
  static getPath(): string {
    const publicUrl = process.env.PUBLIC_URL
    const appName = getAppName()

    switch (appName) {
      case AppNameEnum.UME:
        return `${publicUrl}/manifest_ume.json`
      default:
        return `${publicUrl}/manifest.json`
    }
  }
}
