import { IRetailerStatus } from '@scudraservicos/coordinator-client/dist/src/services/retailers/interfaces/Retailer.res.interface'
import { StoreStatus } from './Store.dto'

export enum RetailerGroupName {
  ODRES_CRED = 'ODRES_CRED'
}

export interface RetailerGroupDto {
  id: string
  name: string
}

export interface RetailerDto {
  id: string
  name: string
  cnpj: string
  fantasyName: string
  businessName?: string
  email?: string
  phoneNumber?: string
  categories: any
  depositAccountOwnerDocument?: string
  depositAccountOwnerName?: string
  depositAccountOwnerPhoneNumber?: string
  finalBankAccountBankCode?: string
  finalBankAccountAccountType?: string
  finalBankAccountAgencyCode?: string
  finalBankAccountAgencyDigit?: string
  finalBankAccountCode?: string
  finalBankAccountDigit?: string
  intermediaryBankAccountBankCode?: string
  intermediaryBankAccountAccountType?: string
  intermediaryBankAccountAgencyCode?: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode?: string
  intermediaryBankAccountDigit?: string
  status?: IRetailerStatus
  debtFundingPack: DebtFundingPackResponse
  retailerGroup?: RetailerGroupDto
}

export interface GetStoresQuery {
  status?: StoreStatus
}

export declare type DebtFundingPackName = 'AMAZOC' | 'BRASIL' | 'ONETE'

export type LimitType = 'RECURRING_PURCHASE' | 'BIG_PURCHASE'
export const LIMIT_TYPES: LimitType[] = ['RECURRING_PURCHASE', 'BIG_PURCHASE']
export enum LIMIT_TYPES_ENUM {
  RECURRING_PURCHASE = 'RECURRING_PURCHASE',
  BIG_PURCHASE = 'BIG_PURCHASE',
}

export interface DebtFundingPackResponse {
  createdOn: string
  id: string
  name: DebtFundingPackName
  uuid: string
  integrationName: string
  description: string
}

export interface IRetailerStatusResponse {
  status: RETAILER_STATUS
  intermediaryBankAccount: RETAILER_STATUS
  registeredAtBmp: RETAILER_STATUS
  originationsParameters: RETAILER_STATUS
  financingOptions: RETAILER_STATUS
  applicationPolicy: RETAILER_STATUS
  stores: RETAILER_STATUS
  limitPolicy: RETAILER_STATUS
}

export enum RETAILER_STATUS {
  INCOMPLETE_INTERMEDIARY_BANK_ACCOUNT = 'INCOMPLETE_INTERMEDIARY_BANK_ACCOUNT',
  CNPJ_NOT_AT_BMP = 'CNPJ_NOT_AT_BMP',
  INCOMPLETE_ORIGINATIONS_PARAMETERS = 'INCOMPLETE_ORIGINATIONS_PARAMETERS',
  FINANCING_OPTIONS_NOT_FOUND = 'FINANCING_OPTIONS_NOT_FOUND',
  APPLICATION_POLICY_NOT_FOUND = 'APPLICATION_POLICY_NOT_FOUND',
  STORES_NOT_FOUND = 'STORES_NOT_FOUND',
  INCOMPLETE_LIMIT_POLICIES = 'INCOMPLETE_LIMIT_POLICIES',
  OK = 'OK',
  INCOMPLETE = 'INCOMPLETE',
}
