import axios, { AxiosInstance } from 'axios'
import StringUtils from '../../../utils/string.utils'
import { getAxiosInstance } from '../utils'
import {
  BorrowerDto,
  IPatchBorrowers,
  IPatchPhoneVerificationByManager,
  PreCreditEvaluationResponse,
} from './dto/Borrower.dto'
import { ImageDto } from './dto/BorrowerImage.dto'
import { CommunicationChannelLabel } from './dto/BorrowerPhone.dto'

export default class BorrowersService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async createSecondaryPhoneNumber(borrowerId: string, phoneNumber: string): Promise<void> {
    await this.axiosInstance.put<void>(`/borrowers/${borrowerId}/phones/${phoneNumber}/secondary`)

    return
  }

  async updateBorrowerBillDueDay(borrowerId: string, billDueDay: number): Promise<BorrowerDto> {
    const response = await this.axiosInstance.patch<BorrowerDto>(`/borrowers/${borrowerId}/bill-due-day/`, {
      billDueDay: billDueDay,
    })

    return response.data
  }

  async deleteSecondaryPhone(borrowerId: string, phoneNumber: string): Promise<void> {
    const response = await this.axiosInstance.delete(`borrowers/${borrowerId}/phones/${phoneNumber}/secondary`)

    return response.data
  }

  async fetchBorrowerPortrait(borrowerId: string): Promise<ImageDto> {
    const response = await this.axiosInstance.get<ImageDto>(`/borrowers/${borrowerId}/images/portrait`)

    return response.data
  }

  async findBorrowerByCpf(cpf: string): Promise<BorrowerDto> {
    const response = await this.axiosInstance.get<BorrowerDto>(`borrowers/borrowers/find-one`, {
      params: { cpf },
    })
    return response.data
  }

  async sendPrimaryPhoneVerificationCode(
    borrowerId: string,
    phoneNumber: string,
    communicationChannel: CommunicationChannelLabel = CommunicationChannelLabel.SMS,
    retailerId?: string
  ): Promise<void> {
    if (communicationChannel === CommunicationChannelLabel.NO_CHANNEL) {
      communicationChannel = CommunicationChannelLabel.SMS
    }

    const body = {
      communicationChannel: communicationChannel,
      retailerId: retailerId
    }
    const response = await this.axiosInstance.post<void>(
      `/borrowers/${borrowerId}/phones/${phoneNumber}/verification-code`,
      body
    )

    return response.data
  }

  async sendPrimaryPhone(borrowerId: string, phoneNumber: string): Promise<void> {
    const body = { phoneNumber }
    const response = await this.axiosInstance.post<void>(`/borrowers/${borrowerId}/phones/`, body)

    return response.data
  }

  async updateBorrower(borrowerId: string, body: IPatchBorrowers): Promise<BorrowerDto> {
    const normalizedBody = { ...body, name: StringUtils.normalize(body?.name) }
    const response = await this.axiosInstance.patch<BorrowerDto>(`/borrowers/${borrowerId}`, normalizedBody)
    return response.data
  }

  async verifyPrimaryPhoneVerificationCode(
    borrowerId: string,
    phoneNumber: string,
    verificationCode: string,
    storeId?: string
  ): Promise<PreCreditEvaluationResponse> {
    const response = await this.axiosInstance.patch(`/borrowers/${borrowerId}/phones/${phoneNumber}/verify`, {
      verificationCode,
      storeId,
    })
    return response.data
  }

  async verifyPrimaryPhoneVerificationCodeByManager(
    borrowerId: string,
    phoneNumber: string,
    managerInformation: IPatchPhoneVerificationByManager
  ): Promise<void> {
    await this.axiosInstance.patch(`/borrowers/${borrowerId}/phones/${phoneNumber}/authorize`, managerInformation)

    return
  }
}
