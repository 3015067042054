import React from 'react'
import QRCode from 'react-qr-code'
import styled from 'styled-components'
import { SectionContent } from './SectionContent'

interface QRCodeInstructionsProps {
  biometryUrl: string
}

export const LivenessQRCodeInstructions: React.FC<QRCodeInstructionsProps> = ({ biometryUrl }) => (
  <>
    <Section>
      <SectionContent title="Ler o QR Code abaixo para acessar a validação facial" text="A leitura do QR Code pode ser realizada pelo cliente ou pelo operador da loja." />
    </Section>

    {biometryUrl && (
      <QRCodeWrapper>
        <StyledQRCode value={biometryUrl} />
      </QRCodeWrapper>
    )}
  </>
)

const Section = styled.div`
  margin-bottom: 24px;
`

const StyledQRCode = styled(QRCode)`
  height: auto;
  width: 184px;
`

const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`
