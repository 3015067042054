import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { bffParceirosApiService } from '../../../services/bff'
import { GetOriginationCodeQueryParams } from '../../../services/bff/originations/dto/OriginationCode.dto'
import { IPatchVerifyOriginationCode, IPostCreateOriginationVerificationBody } from '../../../services/bff/originations/dto/PhoneVerificationRecord.dto'
import PurchaseVerificationSliceReducer from './purchase-verification.reducer'

function* createPurchaseVerification(action: PayloadAction<IPostCreateOriginationVerificationBody>) {
  try {
    const response = yield bffParceirosApiService.originations.createOriginationVerification(action.payload)
    yield put(PurchaseVerificationSliceReducer.actions.createPurchaseVerificationSuccess(response))
  } catch (error) {
    yield put(PurchaseVerificationSliceReducer.actions.createPurchaseVerificationError(error))
  }
}

function* fetchPurchaseVerification(action: PayloadAction<GetOriginationCodeQueryParams>) {
  try {
    const response = yield bffParceirosApiService.originations.getOriginationCodeStatus(action.payload)
    yield put(PurchaseVerificationSliceReducer.actions.fetchPurchaseVerificationSuccess(response))
  } catch (error) {
    yield put(PurchaseVerificationSliceReducer.actions.fetchPurchaseVerificationError(error))
  }
}

function* verifyPurchaseVerification(action: PayloadAction<IPatchVerifyOriginationCode>) {
  try {
    yield bffParceirosApiService.originations.verifyOriginationCode(action.payload)
    yield put(PurchaseVerificationSliceReducer.actions.verifyPurchaseVerificationSuccess())
  } catch (error) {
    yield put(PurchaseVerificationSliceReducer.actions.verifyPurchaseVerificationError(error))
  }
}

const purchaseVerificationSaga = [
  takeLatest(PurchaseVerificationSliceReducer.actions.fetchPurchaseVerification, fetchPurchaseVerification),
  takeLatest(PurchaseVerificationSliceReducer.actions.verifyPurchaseVerification, verifyPurchaseVerification),
  takeLatest(PurchaseVerificationSliceReducer.actions.createPurchaseVerification, createPurchaseVerification),
]

export default purchaseVerificationSaga
