import { InputGroup } from '@blueprintjs/core'
import SearchIcon from '@material-ui/icons/Search'
import _ from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../../cash-register/common/formatBRL'
import { white } from '../../../ui/utils/_colors'

export interface Product {
  id: string
  name: string
  price: number
  downPayment?: number
  priceMinusDownPayment?: number
}

export type Layout = 'responsive' | 'single-column'
interface ProductSessionProps {
  products: Product[]
  onProductSelect?: (product: Product) => void
  canSelectItems?: boolean
  layout?: Layout
}

interface Filter {
  name: string
}

export const ProductsSession: React.FC<ProductSessionProps> = (props: ProductSessionProps) => {
  const { canSelectItems, layout = 'responsive' } = props
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [filterValue, setFilterValue] = useState<Filter | undefined>(undefined)

  const handleSelectProduct = (product: Product) => {
    setSelectedProduct(product)

    if (props.onProductSelect) {
      props.onProductSelect(product)
    }
  }

  const onNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue({ ...filterValue, name: e.currentTarget.value.toUpperCase() })
  }

  const applyFilter = (list: Product[], filter?: Filter): Product[] => {
    if (!filter) return list

    return list.filter(product => {
      let result = true
      if (filter.name) {
        const splittedNameFilter = filter.name.replace(/\s\s+/g, ' ').split(' ')
        const productName = product.name.toUpperCase()
        result = result && splittedNameFilter.every(word => productName.includes(word))
      }
      return result
    })
  }

  const ProductCard: React.FC<{ product: Product }> = ({ product }) => (
    <Card>
      <ListContainer>
        {canSelectItems ? (
          <InputRadioContainer>
            <InputRadio
              id="selectProduct"
              name="products"
              type="radio"
              checked={_.isEqual(selectedProduct, product)}
              onChange={() => handleSelectProduct(product)}
            />
          </InputRadioContainer>
        ) : null}
        <div>
          <ProductTitle>{product.name}</ProductTitle>
          <Price>{formatBRL(product.price)}</Price>
          {product.downPayment ? <DownPayment>Entrada de {formatBRL(product.downPayment)}</DownPayment> : null}
        </div>
      </ListContainer>
    </Card>
  )

  const ProductList: React.FC<{ products: Product[] }> = (props: { products: Product[] }) => (
    <List layout={layout}>
      {props.products.map((product, index) => (
        <ProductCard key={index} product={product} />
      ))}
    </List>
  )

  const sortedProducts = props.products.sort((a, b) => { return a.name.localeCompare(b.name) })

  return (
    <MainContainer>
      <Title>Celulares disponíveis para esse cliente</Title>
      <FilterContainer>
        <TextInput
          placeholder="Busque pelo nome do aparelho..."
          type={'text'}
          tabIndex={1}
          autoComplete="off"
          value={filterValue?.name}
          onChange={onNameFilterChange}
          rightElement={<TextInputIcon />}
        />
      </FilterContainer>
      <Scroll>
        <ProductList products={applyFilter(sortedProducts, filterValue)} />
      </Scroll>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
`

const Title = styled.h1`
  font-family: Roboto;
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
  color: black;
  text-align: left;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0px 24px 0px;
`
const baseWidth = 400;

const TextInput = styled(InputGroup)`
  width: ${baseWidth}px;
  margin-left: 2px;
  @media (max-width: ${baseWidth}px) {
    width: ${baseWidth - 100}px;
  }
`

const TextInputIcon = styled(SearchIcon)`
    color: var(--Grayscale-500, #AAB6BF);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 4px 4px;
`

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
  gap: 16px;
`

const Card = styled.div`
  border: 1px solid #CDD6DD;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Roboto;
`

const calculateMaxWidth = (horizontalSpacing: number, baseWidth: number, multiplier: number): number => {
  return horizontalSpacing + baseWidth * multiplier
}

const horizontalSpacing = 100

const List = styled.div<{ layout: Layout }>`
  display: grid;
  ${({ layout }: { layout: Layout }) => {
    if (layout == 'responsive') {
      return `
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: ${calculateMaxWidth(horizontalSpacing, baseWidth, 4)}px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${calculateMaxWidth(horizontalSpacing, baseWidth, 3)}px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${calculateMaxWidth(horizontalSpacing, baseWidth, 2)}px) {
      grid-template-columns: 1fr;
    }
  `
    } else if (layout == 'single-column') {
      return `
    grid-template-columns: 1fr;
  `
    }
  }}
`

const ProductTitle = styled.h3`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: left;
`

const Price = styled.p`
  color: #182026;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-bottom: 8px;
`

const DownPayment = styled.p`
  margin-bottom: 0px;
  display: inline-block;
  padding: 2px 8px 2px 8px;
  border-radius: 10px;
  opacity: 0px;
  background: #4a76cb;
  color: ${white.primary};
  font-size: 12px;
  text-align: left;
`

const InputRadio = styled.input`
  width: 18px;
  height: 18px;
  accent-color: green;
`

const InputRadioContainer = styled.div`
  width: 18px;
  height: 18px;
`

const Scroll = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  height: calc(80%);
  @media (min-width: 480px) {
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 10px;
      border: 3px solid #f1f1f1;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
`