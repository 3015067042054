import axios, { AxiosInstance } from 'axios'
import { APPLICATION_STATUS } from '../../../redux/reducers/application/application.types'
import { sleep } from '../../../utils/sleep'
import { getAxiosInstance } from '../utils'
import { ApplicationDto, ApplicationStatus, ICreateApplication } from './dto/Application.dto'
import { IGetBorrowerApplicationStatus } from './dto/ApplicationStatus.dto'
import { IPostPreApplication, PreApplicationDto } from './dto/PreApplication.dto'
import { ReevaluationDto, ReevaluationRequest } from './dto/Reevaluation.dto'

export default class ApplicationEngineService {
  private axiosInstance: AxiosInstance
  private REEVALUATION_INTERVAL_CHECK = 1000

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getApplicationStatus(borrowerId: string): Promise<IGetBorrowerApplicationStatus> {
    const response = await this.axiosInstance.get<IGetBorrowerApplicationStatus>(
      `application-engine/borrowers/${borrowerId}/applications/status`
    )

    return response.data
  }

  async preApplication(body: IPostPreApplication): Promise<PreApplicationDto> {
    const response = await this.axiosInstance.post<PreApplicationDto>(
      `application-engine/pre-application`,
      body
    )
    return response.data
  }

  async startApplicationEngine(application: ICreateApplication): Promise<ApplicationDto> {
    const response = await this.axiosInstance.post<ApplicationDto>(
      `application-engine/application`,
      application
    )

    return response.data
  }

  async reevaluateBorrower(request: ReevaluationRequest): Promise<ReevaluationDto> {
    const response = await this.axiosInstance.post<ReevaluationDto>(
      `application-engine/reevaluate`,
      request
    )

    return response.data
  }

  async handleReevaluation(request: ReevaluationRequest): Promise<APPLICATION_STATUS> {
    await this.reevaluateBorrower(request)
    return this.handleApplicationInProgress(request.borrowerId)
  }

  async handleApplicationInProgress(borrowerId: string): Promise<APPLICATION_STATUS> {
    await sleep(this.REEVALUATION_INTERVAL_CHECK)

    let applicationStatus = (await this.getApplicationStatus(borrowerId)).status
    while (
      applicationStatus === ApplicationStatus.UNDER_ANALYSIS.toString() ||
      applicationStatus === ApplicationStatus.PRE_APPROVED.toString()
    ) {
      await sleep(this.REEVALUATION_INTERVAL_CHECK)
      applicationStatus = (await this.getApplicationStatus(borrowerId)).status
    }
    return applicationStatus
  }

  async sendApplicationOnlineUrl(borrowerId: string): Promise<void> {
    await this.axiosInstance.post(`application-engine/application/online/url/send`, { borrowerId })
  }
}
