import React from 'react'
import styled from 'styled-components'
import { TransactionalCreditEvaluation } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { green, white } from '../../../ui/utils/_colors'
import { simplifyProducts } from './ProductsHelper'
import { ProductsSession } from './ProductsSession'

interface Props {
  evaluationResult: TransactionalCreditEvaluation
  handleGoToApplication?: () => void
}

export const SmartphoneSaleApproved: React.FC<Props> = (props: Props) => {
  const { evaluationResult, handleGoToApplication } = props

  return (
    <MainContainer>
      <ProductsSession
        products={simplifyProducts(evaluationResult?.products)}
        canSelectItems={false}
        layout={'single-column'}
      ></ProductsSession>
      <Footer>
        <ConfirmButton onClick={handleGoToApplication} backgroundColor={white.primary} textColor={green.dark1}>
          Cadastrar cliente
        </ConfirmButton>
      </Footer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  padding: 24px;

  display: flex;
  flex-direction: column;
  text-align: left;
  height: calc(100% - 56px);

  @media (max-width: 460px) {
    margin: 0px 8px 0px 8px;
    padding: 10px 8px 20px 8px;
  }
  justify-content: space-between;

  max-width: 460px;
  margin: auto;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2vh;
`
const ConfirmButton = styled.button`
  margin-top: 1vh;
  background: ${(props: any) => (props && props.disabled ? green.light3 : green.dark1)};
  border-radius: 10px;
  border: 0px;
  padding: 12px;
  height: 48px;
  width: 100%;
  max-width: 328px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${white.primary};
`
