import { H5 } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'
import { gray } from '../../../ui/utils/_colors'

interface SectionContentProps {
  title?: string
  text: string | React.ReactNode
}

export const SectionContent: React.FC<SectionContentProps> = ({ title, text }) => (
  <>
    {title && <SectionTitle>{title}</SectionTitle>}
    <SectionText>{text}</SectionText>
  </>
)

const SectionTitle = styled(H5)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-family: 'Inter';
  margin-bottom: 8px;
`

const SectionText = styled.p`
  color: ${gray.scale700};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Inter';
`
