import React, { useState } from 'react'
import styled from 'styled-components'
import AvailableProductsSection from '../../../../molecules/AvailableProducts/AvailableProductsSection'
import { green, white } from '../../../../ui/utils/_colors'
import ApplicationSliceReducer from '../../../../redux/reducers/application/application.reducer'
import { useDispatch } from 'react-redux'
import { QRCode } from 'react-qrcode-logo'
import StepHeader from './StepHeader'
import { ReactComponent as CheckCircleIcon } from '../../../../common/assets/icons/check_circle.svg'
import { ReactComponent as ShareToMobileIcon } from '../../../../common/assets/icons/share_to_mobile.svg'
import { ReactComponent as ActionSendToMobile } from '../../../../common/assets/icons/action_send_to_mobile.svg'
import { ReactComponent as ActionKeepApplication } from '../../../../common/assets/icons/action_keep_application.svg'
import UmeLinks from '../../../../common/UmeLinks'
import { bffParceirosApiService } from '../../../../services/bff'
import { useTypedSelector } from '../../../../redux/reducers/selectors'
import OperatorServiceCenterSliceReducer from '../../../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { PagesPathEnum } from '../../../../pages/enums/pages-path.enum'
import { navigateToPath } from '../../../../common/Navigation'

const ShowApplicationOnlineForPreApprovedStep = () => {
  const dispatch = useDispatch()
  const [showQRCode, setShowQRCode] = useState(false)
  const [qrCodeLink] = useState(UmeLinks.ONLINE_APPLICATION_URL)
  const borrowerId = useTypedSelector((state) => state.application.borrowerId)

  const handleNextPage = () => {
    dispatch(ApplicationSliceReducer.actions.nextPage())
  }

  const handleSendApplicationOnlineUrl = () => {
    setShowQRCode(true)
    if (borrowerId) {
      bffParceirosApiService.applicationEngine.sendApplicationOnlineUrl(borrowerId)
    }
  }

  const handleClose = () => {
    // TODO(lucas.citolin): Although this is an application-screen, it shouldnt be so coupled with sales center
    dispatch(ApplicationSliceReducer.actions.resetState())
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    navigateToPath(PagesPathEnum.OPERATOR_SERVICE_CENTER)
  }

  if (showQRCode) {
    return (
      <Container>
        <Content>
          <StepHeader
            icon={<ShareToMobileIcon />}
            title="Link enviado para o telefone"
            subtitle="Em caso de problemas, o cliente pode ler o QR Code abaixo ou você mesmo pode optar por cadastrá-lo."
            onClose={handleClose}
          />

          <QRCodeContainer>
            <QRCode value={qrCodeLink} size={180} />
          </QRCodeContainer>
        </Content>

        <ButtonContainer>
          <ContinueButton onClick={handleNextPage}>Continuar cadastro</ContinueButton>
        </ButtonContainer>
      </Container>
    )
  }

  return (
    <Container>
      <StepHeader
        icon={<CheckCircleIcon />}
        title="Cliente com limite disponível"
        subtitle="É necessário a finalização do cadastro para utilizar o limite aprovado. Selecione uma das opções abaixo para continuar."
        onClose={handleClose}
      />

      <AvailableProductsContainer>
        <AvailableProductsSection
          containerStyle={{ height: '100%' }}
          userAvailableProducts={[
            {
              title: 'Cadastrar cliente',
              icon: <ActionKeepApplication />,
              onButtonPress: handleNextPage,
              shouldRender: true,
            },
            {
              title: 'Enviar link de cadastro',
              icon: <ActionSendToMobile />,
              onButtonPress: handleSendApplicationOnlineUrl,
              shouldRender: true,
            },
          ]}
        />
      </AvailableProductsContainer>
    </Container>
  )
}

export default ShowApplicationOnlineForPreApprovedStep

const AvailableProductsContainer = styled.div`
  width: 100%;
  flex: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: space-between;
  height: 93%;
  position: relative;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonContainer = styled.div`
  padding: 24px;
  width: 100%;
`

const QRCodeContainer = styled.div`
  margin: 32px 0;
  padding: 16px;
  background: white;
  border-radius: 8px;
`

const ContinueButton = styled.button`
  width: 90%;
  height: 48px;
  background-color: ${white.primary};
  border: 1px solid ${green.dark2};
  color: ${green.dark2};
  border-radius: 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
  display: block;
  margin: 0 auto;

  &:hover {
    opacity: 0.9;
  }
`
