import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { ApplicationConfig, defaultApplicationConfig } from './application.configurations'
import { BorrowerConfigurations, defaultBorrowerConfig } from './borrower.configurations'
import { BudgetConfigurations, defaultBudgetConfig } from './budgets.configurations'
import { defaultOperatorConfig, OperatorConfigurations } from './operator.configurations'
import { defaultSmartphoneSaleConfig, SmartphoneSaleConfigurations } from './smartphone-sale.configurations'
import { defaultTransactionalCreditConfig, TransactionalCreditConfigurations } from './transactional-credit.configurations'

export type Configurations = {
  application: ApplicationConfig
  borrower: BorrowerConfigurations
  transactionalCredit: TransactionalCreditConfigurations
  smartphoneSale: SmartphoneSaleConfigurations
  budgets?: BudgetConfigurations
  operator?: OperatorConfigurations
}

interface IConfigurationsReducerState {
  isLoadingConfigurations: boolean
  hasFetchedConfigurations: boolean
  configurations: Configurations
}

const initialState: IConfigurationsReducerState = {
  configurations: {
    application: defaultApplicationConfig,
    borrower: defaultBorrowerConfig,
    transactionalCredit: defaultTransactionalCreditConfig,
    smartphoneSale: defaultSmartphoneSaleConfig,
    budgets: defaultBudgetConfig,
    operator: defaultOperatorConfig,
  },
  isLoadingConfigurations: false,
  hasFetchedConfigurations: false,
}

export type IFetchConfigurationsPayload = {}

const ConfigurationsSliceReducer = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    fetchConfigurations: (state, action: PayloadAction<IFetchConfigurationsPayload>) => {
      return { ...state, isLoadingConfigurations: true }
    },
    fetchConfigurationsSuccess: (state, action: PayloadAction<Configurations>) => {
      return {
        ...state,
        configurations: action.payload,
        isLoadingConfigurations: false,
        hasFetchedConfigurations: true,
      }
    },
    fetchConfigurationsError: (state, action: PayloadAction<AxiosError>) => {
      return { ...state, isLoadingConfigurations: false }
    },
    handleDeactivatedStore: (state, action: PayloadAction<undefined>) => {
      return state
    },
  },
})

export default ConfigurationsSliceReducer
