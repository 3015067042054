import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { history } from '../../App'
import { isCompletedApplication } from '../../common/ApplicationHelper'
import { Loading } from '../../molecules/Budget'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import ApplicationSliceReducer from '../../redux/reducers/application/application.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import {
  EvaluationResultEnum,
  TransactionalCreditEvaluation,
} from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { SmartphoneSaleApproved } from './components/SmartphoneSaleApproved'
import { SmartphoneSaleDenied } from './components/SmartphoneSaleDenied'

enum Result {
  Approved,
  Denied,
}

interface Props {
  evaluationResult: TransactionalCreditEvaluation
}

export const SmartphoneSaleEvaluation: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const { evaluationResult } = props
  const { isLoadingPreApplication, preApplicationStatus } = useTypedSelector(state => {
    return {
      isLoadingPreApplication: state.application.isLoadingPreApplication,
      preApplicationStatus: state.application?.preApplicationResponse?.status,
    }
  })

  useEffect(() => {
    dispatch(ApplicationSliceReducer.actions.fetchPreApplication({ cpf: evaluationResult.document }))
  }, [])

  const defineResult = (evaluationResult: TransactionalCreditEvaluation) => {
    if (evaluationResult.result == EvaluationResultEnum.APPROVED) {
      return Result.Approved
    }
    return Result.Denied
  }

  const handleGoBackToHomePage = () => {
    window.location.href = '/'
  }

  const handleGoToApplication = (cpf: string) => {
    dispatch(ApplicationSliceReducer.actions.redirectToApplication({ document: cpf }))
  }

  const handleGoToCheckout = (cpf: string) => {
    return history.push({
      pathname: PagesPathEnum.PURCHASE,
      state: { cpf },
    })
  }

  if (isLoadingPreApplication) {
    return <Loading />
  }

  const hasCompletedApplication = !!preApplicationStatus && isCompletedApplication(preApplicationStatus)

  const result = defineResult(evaluationResult)

  if (result === Result.Approved && hasCompletedApplication) {
    handleGoToCheckout(evaluationResult.document)
    return <Loading />
  }

  switch (result) {
    case Result.Approved: {
      return (
        <SmartphoneSaleApproved
          evaluationResult={evaluationResult}
          handleGoToApplication={() => handleGoToApplication(evaluationResult.document)}
        />
      )
    }
    case Result.Denied: {
      return <SmartphoneSaleDenied handleGoBackToHomePage={handleGoBackToHomePage} />
    }
  }
}
