export default class DocumentUtils {
  static CPF_SIZE_WITHOUT_DIGITS = 11

  static maskCpf(cpf?: string): string {
    if (!cpf || cpf.length !== this.CPF_SIZE_WITHOUT_DIGITS) return ''

    const cleanCpf = cpf.replace(/\D/g, '')

    return `${cleanCpf.slice(0, 3)}.***.***-${cleanCpf.slice(3)}`
  }

  /**
   * Formats a masked document from format (027******05) to display format (027.***.***-05)
   * @param maskedDocument - Document string in format (e.g., "027******05")
   * @returns Formatted document string (e.g., "027.***.***-05")
   */
  static formatMaskedDocument(maskedDocument: string): string {
    if (!maskedDocument || maskedDocument.length !== this.CPF_SIZE_WITHOUT_DIGITS) return maskedDocument

    const firstThree = maskedDocument.slice(0, 3)
    const lastTwo = maskedDocument.slice(9, 11)

    return `${firstThree}.***.***-${lastTwo}`
  }
}
