import { Button, Dialog, H5, Icon } from '@blueprintjs/core'
import React from 'react'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'
import { formatDDGPhoneNumber, formatCellphoneWithoutNinthDigit } from '../../../cash-register/common/formatCellPhone'
import * as config from '../../../config/config'
import { gray, green, red, white } from '../../../ui/utils/_colors'

interface ManualVerificationDialogProps {
  isOpen: boolean
  formattedCellphone: string
  verificationCode: string
  isVerifyingPurchaseVerification: boolean
  isPurchaseVerificationVerified: boolean
  onVerificationCodeChange: (values: { value: string }) => void
  onConfirmVerificationCode: () => void
  handlePressCancel: (event: React.KeyboardEvent) => void
  onNextStep: () => void
  handleCancel: () => void
}

export const ManualVerificationDialog: React.FC<ManualVerificationDialogProps> = ({
  isOpen,
  formattedCellphone,
  verificationCode,
  isVerifyingPurchaseVerification,
  isPurchaseVerificationVerified,
  onVerificationCodeChange,
  onConfirmVerificationCode,
  handlePressCancel,
  onNextStep,
  handleCancel,
}) => {
  return (
    <StyledDialog isOpen={isOpen}>
      <Container onKeyDown={handlePressCancel}>
        <Content>
          <Title>Estamos avaliando sua compra</Title>

          <SectionText>
            Não conseguimos validar a compra por reconhecimento facial.
            <br /> Em instantes um atendente entrará em contato pelo número <SectionTextBold>{formattedCellphone}</SectionTextBold>.
          </SectionText>
          <Section>
            <SectionTitle>Dúvidas? Fale conosco:</SectionTitle>
            <SectionText>
              {formatDDGPhoneNumber(config.UME_PHONES.customerService.phoneNumber)} ou WhatsApp: {formatCellphoneWithoutNinthDigit(config.UME_PHONES.customerService.whatsapp)}
            </SectionText>
            <ButtonContainer>
              <NumberFormat
                id="sms-code-input"
                className={`bp3-input ${verificationCode && verificationCode.length < 6 ? 'bp3-intent-danger' : 'bp3-intent-none'} `}
                placeholder="Código de Verificação"
                autoComplete="off"
                tabIndex={1}
                format="### ###"
                mask="_"
                value={verificationCode}
                disabled={isPurchaseVerificationVerified}
                onValueChange={values => onVerificationCodeChange(values)}
              />
              <ConfirmButton
                style={{ width: '12em' }}
                loading={isVerifyingPurchaseVerification}
                intent="success"
                text={!isPurchaseVerificationVerified && 'Checar código'}
                onClick={onConfirmVerificationCode}
                disabled={isPurchaseVerificationVerified}
              >
                {isPurchaseVerificationVerified && <Icon icon="tick" />}
              </ConfirmButton>
            </ButtonContainer>
          </Section>

          <ButtonContainer>
            <ConfirmButton disabled={!isPurchaseVerificationVerified} intent="success" text="Finalizar" onClick={onNextStep} />
            <CancelButton intent="danger" text="Cancelar" onClick={handleCancel} />
          </ButtonContainer>
        </Content>
      </Container>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  padding: 32px;
  background-color: ${white.primary};
  width: 600px;
  border-radius: 8px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
`

const Content = styled.div`
  width: 100%;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  font-family: 'Inter';
  margin-bottom: 16px;
`

const Section = styled.div`
  margin-bottom: 24px;
`

const SectionTitle = styled(H5)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-family: 'Inter';
  margin-bottom: 8px;
`

const SectionText = styled.p`
  color: ${gray.scale700};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Inter';
`

const SectionTextBold = styled.text`
  color: ${gray.scale700};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Inter';
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 16px;
`

const ConfirmButton = styled(Button)`
  background: ${green.dark1} 
  color: white;
  border-radius: 4px;
  border: 1px ${green.dark2};

  &:hover {
    background-color: ${(props: any) => (props && props.disabled ? green.dark1 : green.dark2)}

  &:disabled {
    opacity: 0.4;
  }
`

const CancelButton = styled(Button)`
  background-color: ${red.light2}
  color: white;
  border-radius: 4px;
  border: 1px ${red.dark2};

  &:hover {
    background-color: ${red.dark2}
  }
`
