import React, { useEffect, useState } from 'react'
import { Dropdown } from '../../atoms/Dropdown/Dropdown'
import LocalStorageWrapper, { LocalStorageItem } from '../../common/assets/utils/LocalStorageWrapper'
import { StoreDto } from '../../services/bff/retailers/dto/Store.dto'
import { CaptureMethodConfig, CaptureMethodService, CaptureMethodUIConfig } from '../../services/captureMethodService/CaptureMethodService'

interface CaptureMethodSelectionProps {
  store: StoreDto
}

export const CaptureMethodSelection: React.FC<CaptureMethodSelectionProps> = ({ store }) => {
  const savedCaptureMethod = CaptureMethodService.get()

  const [selectedCaptureMethod, setSelectedCaptureMethod] = useState<CaptureMethodConfig | undefined>(undefined)
  const [allowedCaptureMethods, setAllowedCaptureMethods] = useState<CaptureMethodUIConfig[] | undefined>(undefined)

  useEffect(() => {
    const handleCaptureMethod = async () => {
      if (!store) return

      const captureMethods = await getCaptureMethodConfigs(store)

      setSelectedCaptureMethod(savedCaptureMethod.captureMethod)
      setAllowedCaptureMethods(captureMethods)
    }

    handleCaptureMethod()
  }, [])

  const getCaptureMethodConfigs = async (store: StoreDto) => {
    const localStorageOperator = LocalStorageWrapper.getItem(LocalStorageItem.OPERATOR)
    return CaptureMethodService.allowedCaptureMethods(localStorageOperator?.role, store?.settings?.captureMethod)
  }

  const onCaptureMethodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const captureMethod = e.currentTarget.value as CaptureMethodConfig
    CaptureMethodService.set({ captureMethod })
    setSelectedCaptureMethod(captureMethod)
  }

  return (
    <div>
      <Dropdown
        value={savedCaptureMethod.label}
        key={selectedCaptureMethod}
        onChange={onCaptureMethodChange}
        options={
          allowedCaptureMethods?.map(method => ({
            id: method.captureMethod,
            name: method.label,
          })) || []
        }
      />
    </div>
  )
}
