import { put, takeLatest } from 'redux-saga/effects'
import LocalStorageWrapper, { LocalStorageItem } from '../../../common/assets/utils/LocalStorageWrapper'
import { bffParceirosApiService } from '../../../services/bff'
import PublicConfigurationsSliceReducer, { PublicConfigurations } from './public-configurations.reducer'

function* fetchConfigurations() {
  try {
    let configurations: PublicConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.PUBLIC_CONFIGURATIONS)
    if (!configurations) {
      configurations = yield bffParceirosApiService.publicConfigurations.fetchPublicConfigurations()
      if (configurations) LocalStorageWrapper.setItem(LocalStorageItem.PUBLIC_CONFIGURATIONS, configurations)
    }

    document.title = String(configurations?.app?.name)

    yield put(PublicConfigurationsSliceReducer.actions.fetchConfigurationsSuccess(configurations))
  } catch (error) {
    yield put(PublicConfigurationsSliceReducer.actions.fetchConfigurationsError(error))
  }
}

const publicConfigurationsSaga = [
  takeLatest(PublicConfigurationsSliceReducer.actions.fetchConfigurations, fetchConfigurations),
]

export default publicConfigurationsSaga
