export enum VerificationStatus {
  PENDING = 'PENDING',
  MANUAL = 'MANUAL',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export enum VerificationStep {
  ANALYST_VERIFICATION = 'ANALYST_VERIFICATION',
}

export enum VerificationMethod {
  LIVENESS_WITH_ANALYST = 'LIVENESS_WITH_ANALYST',
  LIVENESS = 'LIVENESS',
  ANALYST_CALL = 'ANALYST_CALL',
  OTP = 'OTP',
}

export enum ExternalReferenceName {
  PROPOSAL = 'PROPOSAL',
  USER = 'USER',
  CONTRACT = 'CONTRACT',
  APPLICATION = 'APPLICATION',
}

export enum ProcessName {
  ORIGINATION_AUTH = 'ORIGINATION_AUTH',
  MOBILE_APP_LOGIN = 'MOBILE_APP_LOGIN',
  APPLICATION_PHONE_VERIFICATION = 'APPLICATION_PHONE_VERIFICATION',
}

export enum BIOMETRY_FLOW {
  IDCHECK = 'idcheck',
}
