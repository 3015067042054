import { ProductResponse } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { Product } from './ProductsSession'

export const simplifyProducts = (products?: ProductResponse[]): Product[] => {
  if (!products) return []

  return products.flatMap(product =>
    product.offers.map(offer => ({
      id: product.id,
      name: `${product.manufacturer} ${product.model}${product.description ? ` ${product.description}` : ''}`,
      price: offer.price,
      downPayment: offer.downPayment,
      priceMinusDownPayment: offer.priceMinusDownPayment,
    }))
  )
}
