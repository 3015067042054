import React from 'react'
import styled from 'styled-components'
import { black, gray } from '../../../../ui/utils/_colors'
import { CloseButton } from '../../../../atoms/Buttons/CloseButton'

interface StepHeaderProps {
  icon: React.ReactNode
  title: string
  subtitle: string
  onClose?: () => void
}

const StepHeader = ({ icon, title, subtitle, onClose }: StepHeaderProps) => {
  return (
    <HeaderContainer>
      {onClose && (
        <StyledCloseButtonWrapper>
          <CloseButton onCloseButtonClick={onClose} />
        </StyledCloseButtonWrapper>
      )}
      <ContentContainer>
        <IconContainer>{icon}</IconContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </ContentContainer>
    </HeaderContainer>
  )
}

export default StepHeader

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;

  padding: 16px;
`

const StyledCloseButtonWrapper = styled.div`
  opacity: 0.8;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 24px;
`

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
`

const Title = styled.h1`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  color: ${black.primary};
  margin: 10px 0 8px 0;
`

const Subtitle = styled.p`
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  color: ${gray.scale700};
  margin: 0;
`
