import React from 'react'
import styled from 'styled-components'
import LocalStorageWrapper, { LocalStorageItem } from '../../../common/assets/utils/LocalStorageWrapper'
import { green } from '../../../ui/utils/_colors'
import ApplicationLayout from '../ApplicationLayout'
import ShowApplicationOnlineForPreApprovedStep from './PreApprovedSteps/ShowApplicationOnlineForPreApprovedStep'

const ApplicationPreApprovedStep = () => {
  const configurations = LocalStorageWrapper.getItem(LocalStorageItem.CONFIGURATIONS)

  const shouldShowApplicationOnlineForPreApproved = configurations?.application.showApplicationOnlineForPreApproved

  if (shouldShowApplicationOnlineForPreApproved) {
    return <ShowApplicationOnlineForPreApprovedStep />
  }

  return (
    <ApplicationLayout title="" subtitle="" isNextButtonEnabled={true}>
      <MainContainer>
        <h1>
          Este cliente tem <br />
          <GreenText>crédito pré-aprovado!</GreenText>
        </h1>
        <p>Complete o cadastro para liberar o seu limite.</p>
      </MainContainer>
    </ApplicationLayout>
  )
}

export default ApplicationPreApprovedStep

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-y: auto;
  height: calc(100% - 56px);
  width: 100%;
  padding: 10px 0px;
`

const GreenText = styled.span`
  color: ${green.dark1};
`
