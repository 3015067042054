import { Configurations } from '../redux/reducers/configurations/configurations.reducer'
import { PublicConfigurations } from '../redux/reducers/public-configurations/public-configurations.reducer'

export const isSmartphoneSaleAllowed = (configurations?: Configurations): boolean => {
  return configurations?.smartphoneSale?.isAllowed ?? false
}

export const isTransactionalCreditAllowed = (configurations?: Configurations): boolean => {
  return (
    (configurations?.transactionalCredit?.isAllowed ?? false) &&
    !isSmartphoneSaleAllowed(configurations)
  )
}

export const isServiceCentralAllowed = (configurations?: Configurations): boolean => {
  return !isSmartphoneSaleAllowed(configurations) && !isTransactionalCreditAllowed(configurations)
}

export const isPurchaseButtonAllowed = (configurations?: PublicConfigurations): boolean => {
  return !!configurations?.featureFlags.enablePurchaseMenuButton
}

export const isBudgetsButtonAllowed = (
  publicConfigurations?: PublicConfigurations,
  configurations?: Configurations
): boolean => {
  return (
    !!publicConfigurations?.featureFlags.enableBudgetsMenuButton &&
    !!configurations?.budgets?.isAllowed
  )
}

export const isSSOLoginButtonAllowed = (configurations?: PublicConfigurations): boolean => {
  return !!configurations?.featureFlags.enableSSOLoginButton
}

// TODO(lucas.citolin): Use a separate config for telling if the frontend should require secondary phone number
export const requireSecondaryPhoneInApplication = (configurations?: Configurations): boolean => {
  return !!!configurations?.application?.showApplicationOnlineForPreApproved
}
