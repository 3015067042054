import { Button, Dialog, H5, Icon } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import { InfoCard, InfoCardText, WarningIcon } from '../../cash-register/purchases-old/styles'
import { PurchaseVerificationErrors } from '../../common/ErrorStrings'
import { formatPhoneNumber } from '../../common/FormatPhone'
import * as config from '../../config/config'
import { Anchor } from '../../legacy-lib/design-kit-ui'
import PurchaseVerificationSliceReducer from '../../redux/reducers/purchase-verification/purchase-verification.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { VerificationStatus } from '../../services/bff/originations/dto/verification.enum'
import { blue, gray, green, red, white } from '../../ui/utils/_colors'
import { LivenessQRCodeInstructions } from './components/LivenessQRCodeInstructions'
import { SectionContent } from './components/SectionContent'
import { ManualVerificationDialog } from './components/ManualVerificationDialog'

interface LivenessAnalystModalProps {
  isOpen: boolean
  onCancel: () => void
  handleKeyPressCancel: (event: React.KeyboardEvent) => void
  onNextStep: () => void
  proposalId: string
  formattedCellphone: string
}

export const LivenessAnalystModal: React.FC<LivenessAnalystModalProps> = ({ isOpen, onCancel, handleKeyPressCancel, onNextStep, proposalId, formattedCellphone }) => {
  const {
    isCreatingPurchaseVerification,
    createdPurchaseVerification,
    isCreatePurchaseVerificationError,

    isFetchingPurchaseVerification,
    purchaseVerification,
    isFetchingPurchaseVerificationError,

    isVerifyingPurchaseVerification,
    isVerifyPurchaseVerificationError,
    isPurchaseVerificationVerified,
  } = useTypedSelector(state => ({
    isCreatingPurchaseVerification: state.purchaseVerification.isCreatingPurchaseVerification,
    createdPurchaseVerification: state.purchaseVerification.createdPurchaseVerification,
    isCreatePurchaseVerificationError: state.purchaseVerification.isCreatePurchaseVerificationError,

    isFetchingPurchaseVerification: state.purchaseVerification.isFetchingPurchaseVerification,
    isFetchingPurchaseVerificationError: state.purchaseVerification.isFetchingPurchaseVerificationError,
    purchaseVerification: state.purchaseVerification.purchaseVerification,

    isVerifyingPurchaseVerification: state.purchaseVerification.isVerifyingPurchaseVerification,
    isVerifyPurchaseVerificationError: state.purchaseVerification.isVerifyPurchaseVerificationError,
    isPurchaseVerificationVerified: state.purchaseVerification.isPurchaseVerificationVerified,
  }))
  const dispatch = useDispatch()
  const [isApproved, setIsApproved] = useState(false)
  const [biometryIsPending, setBiometryIsPending] = useState(false)
  const [isManualVerification, setIsManualVerification] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  const handleCancel = () => {
    dispatch(PurchaseVerificationSliceReducer.actions.resetPurchaseVerification())
    onCancel()
  }
  const handlePressCancel = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      dispatch(PurchaseVerificationSliceReducer.actions.resetPurchaseVerification())
    }
    handleKeyPressCancel(event)
  }

  const onErrorToCreateLivenessVerification = () => {
    toaster.showErrorToast(PurchaseVerificationErrors.CREATE_PURCHASE_VERIFICATION_ERROR)
  }

  const onErrorToVerifyLiveness = () => {
    toaster.showErrorToast(PurchaseVerificationErrors.FETCH_PURCHASE_VERIFICATION_ERROR)
  }
  const onErrorToVerifyCode = () => {
    toaster.showErrorToast(PurchaseVerificationErrors.VERIFY_PURCHASE_CODE_VERIFICATION_ERROR)
  }

  const onConfirmLivenessVerification = () => {
    dispatch(
      PurchaseVerificationSliceReducer.actions.fetchPurchaseVerification({
        proposalId,
      })
    )
  }

  useEffect(() => {
    if (isOpen && proposalId) {
      dispatch(PurchaseVerificationSliceReducer.actions.resetPurchaseVerification())
      dispatch(
        PurchaseVerificationSliceReducer.actions.createPurchaseVerification({
          proposalId,
        })
      )
    }
  }, [isOpen, dispatch])

  useEffect(() => {
    if (isCreatePurchaseVerificationError) {
      onErrorToCreateLivenessVerification()
      onCancel()
    }
  }, [isCreatePurchaseVerificationError])

  useEffect(() => {
    if (isFetchingPurchaseVerificationError) onErrorToVerifyLiveness()
  }, [isFetchingPurchaseVerificationError])

  useEffect(() => {
    if (isVerifyPurchaseVerificationError) onErrorToVerifyCode()
  }, [isVerifyPurchaseVerificationError])

  useEffect(() => {
    setIsApproved(purchaseVerification?.status === VerificationStatus.APPROVED)
    setBiometryIsPending(purchaseVerification?.status === VerificationStatus.PENDING)
    setIsManualVerification(purchaseVerification?.status === VerificationStatus.MANUAL)
  }, [purchaseVerification?.status])

  const onConfirmVerificationCode = () => {
    dispatch(
      PurchaseVerificationSliceReducer.actions.verifyPurchaseVerification({
        proposalId,
        purchaseCode: verificationCode,
      })
    )
  }

  if (isManualVerification) {
    return (
      <ManualVerificationDialog
        isOpen={isOpen}
        formattedCellphone={formattedCellphone}
        verificationCode={verificationCode}
        isVerifyingPurchaseVerification={isVerifyingPurchaseVerification}
        isPurchaseVerificationVerified={isPurchaseVerificationVerified}
        onVerificationCodeChange={values => setVerificationCode(values.value)}
        onConfirmVerificationCode={onConfirmVerificationCode}
        handlePressCancel={handlePressCancel}
        onNextStep={onNextStep}
        handleCancel={handleCancel}
      />
    )
  }

  if (isCreatingPurchaseVerification) {
    return (
      <LoadingContainer>
        <LoadingSpinner text="Carregando verificação..." />
      </LoadingContainer>
    )
  }

  return (
    <StyledDialog isOpen={isOpen}>
      <Container onKeyDown={handlePressCancel}>
        <Content>
          <Title>Escolha uma das opções para validar a compra com reconhecimento facial</Title>
          <StyledAnchor
            icon={<Icon icon="arrow-top-right" size={16} color={blue.light} />}
            reverse={true}
            gap="4px"
            onClick={() => window.open(createdPurchaseVerification?.biometry?.biometryUrl, '_blank')}
            label="Continuar neste dispositivo numa nova aba"
          />

          <Section>
            <SectionContent
              title="Cliente acessar o link enviado para o celular cadastrado"
              text="Oriente o cliente a acessar suas mensagens (SMS) ou Whatsapp, clicar no link e realizar o reconhecimento facial."
            />
          </Section>

          <LivenessQRCodeInstructions biometryUrl={createdPurchaseVerification?.biometry?.biometryUrl!} />

          <StyledInfoCard type="WARNING">
            <WarningIcon />
            <Column>
              <StyledInfoCardText bold={true}>Não feche esta aba</StyledInfoCardText>
              <StyledInfoCardText>Aguarde a finalização do reconhecimento facial para prosseguir.</StyledInfoCardText>
            </Column>
          </StyledInfoCard>

          {isApproved ? (
            <SuccessTextContainer>
              <Icon icon="tick" size={16} color={green.yellowGreen} />
              <ConfirmedText>Validação facial confirmada</ConfirmedText>
            </SuccessTextContainer>
          ) : (
            <ButtonContainer>
              <ConfirmButton loading={isFetchingPurchaseVerification} intent="success" text="Confirmar validação facial" onClick={onConfirmLivenessVerification} />
            </ButtonContainer>
          )}

          {biometryIsPending && !isFetchingPurchaseVerification && <NotConfirmedText>A validação facial não foi confirmada. Verifique se o cliente realizou e tente novamente.</NotConfirmedText>}

          <FooterSection>
            <SectionContent
              title="Após confirmar a validação facial, clique para seguir"
              text={
                <>
                  Problemas com a validação facial? Entre em contato com a Central de Atendimento <SectionTextBold>{formatPhoneNumber(config.UME_PHONES.retailerSAC.phoneNumber)}</SectionTextBold>
                </>
              }
            />
            <ButtonContainer>
              <ConfirmButton disabled={!isApproved} intent="success" text="Finalizar" onClick={onNextStep} />
              <CancelButton intent="danger" text="Cancelar" onClick={handleCancel} />
            </ButtonContainer>
          </FooterSection>
        </Content>
      </Container>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  padding: 32px;
  background-color: ${white.primary};
  width: 600px;
  border-radius: 8px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
`

const Content = styled.div`
  width: 100%;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  font-family: 'Inter';
`

const StyledAnchor = styled(Anchor)`
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Inter';
  color: ${blue.light};
  margin: 16px 0px 16px 0px;
  gap: 4px;
`

const Section = styled.div`
  margin-bottom: 24px;
`

const FooterSection = styled.div`
  border-top: 1px solid ${gray.scale400};
  margin: 32px 0px 0px 0px;
  padding-top: 32px;
`

const SectionTextBold = styled.text`
  color: ${gray.scale700};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Inter';
`

const StyledInfoCard = styled(InfoCard)`
  margin: 32px 0;
  background-color: #fff3e0;
  border: 1px solid #ffb74d;
  border-radius: 4px;
  padding: 16px;
  flex-direction: row;
  justify-content: flex-start;
`

const StyledInfoCardText = styled(InfoCardText)`
  margin: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 16px;
`
const SuccessTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 4px;
`
const ConfirmButton = styled(Button)`
  background: ${green.dark1} 
  color: white;
  border-radius: 4px;
  border: 1px ${green.dark2};

  &:hover {
    background-color: ${(props: any) => (props && props.disabled ? green.dark1 : green.dark2)}

  &:disabled {
    opacity: 0.4;
  }
`

const CancelButton = styled(Button)`
  background-color: ${red.light2}
  color: white;
  border-radius: 4px;
  border: 1px ${red.dark2};

  &:hover {
    background-color: ${red.dark2}
  }
`
const NotConfirmedText = styled.p`
  color: ${red.rubi};
  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter';
  margin-top: 8px;
  line-height: 16px;
  text-align: right;
`

const ConfirmedText = styled.div`
  color: ${green.yellowGreen};
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: 'Inter';
  line-height: 20px;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`
