import { PRE_APPLICATION_STATUS } from '../../../../redux/reducers/application/application.types'

export interface ICreateApplication {
  readonly borrowerId: string
  readonly borrowerZipcode: string
  readonly requestedValue?: number
  readonly storeId: string
  readonly professionId: string
  readonly generatedAt?: IApplicationGeneratedAt
  readonly livenessCheckExecuted?: boolean
  readonly operatorId?: string
}

export type IApplicationGeneratedAt = 'mobile-app' | null

export interface IGetBorrowerApplicationStatus {
  status: ApplicationStatus
}

export enum ApplicationStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  FAILURE = 'FAILURE',
  UNDER_ANALYSIS = 'UNDER_ANALYSIS',
  WAITING_MANUAL_APPROVAL = 'WAITING_MANUAL_APPROVAL',
  PRE_APPROVED = 'PRE_APPROVED',
}

export enum ApplicationType {
  APPLICATION = 'APPLICATION',
  REEVALUATION = 'REEVALUATION',
}

export interface StageResult {
  status: string
  reason: string
  acquiredData?: any
  startedOnTimestamp?: string
  finishedOnTimestamp?: string
  additionalData?: any
  inputAttributesValues?: any
  modelPredictions?: any
}

export interface IPreApplicationResponse {
  bemolStageResult?: StageResult
  borrower?: IGetBorrowerResponse
  limit?: number
  status: PRE_APPLICATION_STATUS
  reason?: string
  application?: IApplicationResponse
}

export interface IStageResult {
  status: string
  reason: string
  acquiredData?: any
  startedOnTimestamp?: string
  finishedOnTimestamp?: string
  additionalData?: any
  inputAttributesValues?: any
  modelPredictions?: any
}

export interface IGetBorrowerResponse {
  id: string
  createdOn: string
  cpf: string
  name?: string
  email?: string
  birthDate?: string
  passwordReset: boolean
}

export enum PRE_APPLICATION_STORAGES {
  PRE_APPLICATION = 'pre-application',
}

export interface ApplicationDto {
  id: string
}

export interface IApplicationStage {
  id: string
  name: StageName
  startedOnTimestamp: string
  finishedOnTimestamp: string
  inputAttributesValues: any
  evaluationResult: ApplicationStageEvaluationResult
  modelPredictions: IModelPredictionResponse[]
}

export const STAGE_NAMES = ['BORROWER_FETCH', 'BIOMETRY', 'PHONE_VERIFICATION', 'BEMOL', 'FIRST_LIMIT', 'SCUDRA_SCORE', 'BVS_INFO', 'SPC_INFO', 'FRONTEND', 'NEOWAY', 'PROFESSION']

export type StageName =
  | 'BORROWER_FETCH'
  | 'BIOMETRY'
  | 'PHONE_VERIFICATION'
  | 'BEMOL'
  | 'FIRST_LIMIT'
  | 'SCUDRA_SCORE'
  | 'BVS_INFO'
  | 'SPC_INFO'
  | 'FRONTEND'
  | 'NEOWAY'
  | 'PROFESSION'
  | 'BVS_POSITIVE_EVALUATION'

export interface ApplicationStageEvaluationResult {
  approved: boolean
  reason?: ReasonOfRefusal
  extra?: any
}

export type ReasonOfRefusal =
  | 'BIOMETRY_PROCESS_FAILURE'
  | 'BIOMETRY_SCORE_BELOW_THRESHOLD'
  | 'BORROWER_NOT_FOUND'
  | 'CREDIT_API_BAD_GATEWAY'
  | 'CREDIT_SCORE_BELOW_THRESHOLD'
  | 'NO_COMPLETED_BIOMETRY_PROCESS_FOUND'
  | 'NO_COMPLETED_PHONE_VERIFICATION_FOUND'
  | 'PHONE_VERIFICATION_NOT_FOUND'
  | 'BEMOL_NOT_FOUND'
  | 'BEMOL_DEFAULT'
  | 'BEMOL_BEHAVIOR_SCORE_BELOW_THRESHOLD'
  | 'BEMOL_APPLICATION_SCORE_BELOW_THRESHOLD'
  | 'FRONTEND_TIMEOUT'
  | 'NEOWAY_NOT_FOUND'
  | 'PROFESSION_GROUP_NOT_FOUND'
  | 'SPC_NOT_FOUND'
  | 'CPF_HAS_SPC_RESTRICTION'
  | 'BEMOL_TAKEN_LIMIT_ABOVE_THRESHOLD'
  | 'BEMOL_OVERDUE_DAYS_ABOVE_THRESHOLD'

export enum APPLICATION_GENERATED_AT {
  MOBILE_APP = 'mobile-app',
}

export enum DenialReasons {
  SPOOF_FACE_IMAGE = 'SPOOF_FACE_IMAGE',
  SPOOF_DOCUMENT_IMAGE = 'SPOOF_DOCUMENT_IMAGE',
  IVALID_DOCUMENT = 'IVALID_DOCUMENT',
  INVALID_FACE_MATCHING = 'INVALID_FACE_MATCHING',
  TAMPERED_DOCUMENT = 'TAMPERED_DOCUMENT',
  ILLEGIBLE_DOCUMENT = 'ILLEGIBLE_DOCUMENT',
  INVALID_OCR = 'INVALID_OCR',
  NOT_IN_AMAZON = 'NOT_IN_AMAZON',
  UNABLE_TO_VALIDATE_PHONE = 'UNABLE_TO_VALIDATE_PHONE',
  FAILED_OPERATOR_VALIDATION = 'FAILED_OPERATOR_VALIDATION',
}

export interface IModelPredictionResponse {
  id: string
  modelId: string
  createdOn: string
  modifiedOn: string
  applicationStageId: string
  contractId: string
  limitId: string
  inputAttributesValues: any
  outputAttributesValues: any
}

export interface IApplicationResponse {
  readonly id: string
  readonly status: string
  readonly borrowerZipcode?: string
  readonly borrowerId: string
  readonly retailerId?: string
  readonly storeId: string
  readonly professionId: string
  readonly operatorId?: string
  readonly policyId: string
  readonly requestedValue?: number
  readonly stages?: IApplicationStage[]
  readonly generatedAt?: IApplicationGeneratedAt
  readonly batchNumber?: number | null
  readonly denialReasons?: DenialReasons[]
  readonly evaluatedByOperatorId?: string
  readonly applicationType?: ApplicationType
  readonly createdOn: string
  readonly reevaluationId?: string
  readonly evaluatedTimestamp?: string
}
