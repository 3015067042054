import { ApplicationVersion, ApplicationVersionEnum } from '../application/application.types'

export type ApplicationConfig = {
  version: ApplicationVersion
  hasPhoneVerification: boolean
  submitBiometry: boolean
  isLivenessAvailable?: boolean
  showApplicationOnlineForPreApproved?: boolean
}

export const defaultApplicationConfig: ApplicationConfig = {
  version: ApplicationVersionEnum.V3,
  hasPhoneVerification: true,
  submitBiometry: false,
}
