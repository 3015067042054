import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { GetOperatorIdentificationResponse } from './dto/OperatorIdentification.dto'
import { sleep } from '../../../utils/sleep'

export default class OperatorsService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getOperatorIdentification(document: string): Promise<GetOperatorIdentificationResponse> {
    const response = await this.axiosInstance.get<GetOperatorIdentificationResponse>(`/operators/identification`, { params: { document } })
    return response.data
  }
}
